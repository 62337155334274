$screen-mobile-min: 0px;
$screen-mobile-max: 465px;
$screen-tablet-min: 465px;
$screen-tablet-max: 1024px;
$screen-desktop-min: 1024px;
$screen-desktop-max: 3000px;
$screen-largeDesktop-min: 3000px;
$screen-largeDesktop-max: 4000px;

@mixin mobile {
	@media (min-width: #{$screen-mobile-min}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$screen-tablet-min}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$screen-desktop-min}) {
		@content;
	}
}

@mixin largeDesktop {
	@media (min-width: #{$screen-largeDesktop-min}) {
		@content;
	}
}
