$blue: #43f4c7;
$orange: #f47443;

.pool-detail-page {
	&__text {
		&--blue {
			color: $blue !important;
		}
	}

	&__button {
		&--orange {
			background-color: $orange !important;
		}
	}
}
