@import "../../shared/scss/media-breakpoint.scss";

$font-family: "Russo One", "sans-serif";
$button-font-size: 16px;

$spacing: 2em;
$padding: 1em;
$padding-sm: 0.5em;
$button-padding: 12px 20px;
$letter-spacing: 1px;
$spacing-sm: 0.5em;

$white: #f0f0f0;
$black: #1e1f35;
$gray: #6c757d;
$orange: #e67f1c;
$red: red;
$violet: #6d4afe;
$violet-darker: #4a4176;
$white--transparent: #f0f0f0;

$container-height: 10vh;
$container-width: 30vw;
$container-height-sm: 60vw;
$container-width-sm: 80vw;

$description-height: 60vh;
$description-height-sm: 20vh;
$description-height-md: 35vh;

$border-size: 0.25em;
$border-radius: 12px;

$white--transparent: rgba(255, 255, 255, 0.5);
$black--transparent: rgba(0, 0, 0, 0.5);
$box-shadow: -13px 26px 67px 28px rgba(0, 0, 0, 0.51);

.popup {
  &__header,
  &__content {
    letter-spacing: $letter-spacing;
    color: $white;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    z-index: 9999;

    &--transparent {
      background-color: $black--transparent;
    }
  }

  &__modal,
  &__container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: $border-size solid;
    background: radial-gradient(circle, $violet-darker -24%, #1e1f35 40%);
  }

  &__modal {
    min-width: 50vw;
    max-width: 90vw;
    min-height: 30vh;
    padding: $spacing;
  }

  &__container {
    min-width: 20vw;
    max-width: $container-width;

    @include mobile {
      max-width: $container-width-sm;
    }

    @include tablet() {
      max-width: $container-width-sm;
    }

    @include desktop {
      max-width: $container-width;
    }

    @include largeDesktop {
      max-width: $container-width;
    }

    &--gray {
      border-color: $gray;
    }

    &--violet {
      border-color: $violet;
    }

    &--red {
      border-color: $red;
    }

    &--orange {
      border-color: $orange;
    }
  }

  &__content {
    padding: $padding;

    @include mobile {
      padding: $padding-sm;
    }

    @include tablet() {
      padding: $padding-sm;
    }

    @include desktop {
      padding: $padding;
    }

    @include largeDesktop {
      padding: $padding;
    }
  }

  &__header {
    width: 100%;
    text-align: center;
    padding-bottom: $padding;
    font-weight: 700;

    &--white {
      border-color: $white;
    }

    &--violet {
      border-color: $violet;
    }

    &--red {
      border-color: $red;
    }

    &--orange {
      border-color: $orange;
    }
  }

  &__description {
    margin: $spacing 0;
    overflow: hidden;
    overflow-y: auto;
    font-weight: 1;
    text-align: center;
    max-height: $description-height;

    @include mobile {
      margin: $spacing-sm 0;
      max-height: $description-height-sm;
    }

    @include tablet() {
      margin: $spacing-sm 0;
      max-height: $description-height-md;
    }

    @include desktop {
      font-size: 1em;
      max-height: $description-height;
    }

    @include largeDesktop {
      font-size: 1em;
      max-height: $description-height;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    padding: $padding;
    padding-top: 0;
  }

  &__button {
    font-family: $font-family;
    font-size: $button-font-size;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    padding: $button-padding;
    color: $white;
    width: 10em;

    &--violet {
      background-color: $violet;
    }

    &--orange {
      background-color: $orange;
    }

    &--red {
      background-color: $red;
    }
  }
}
