@charset "utf-8";

/** 
*
* -----------------------------------------------------------------------------
* Template : GamFi - Metaverse Web3 IGO/IDO Token Launchpad Figma Template
* Author : uigigs
* Author URI : http://www.uigigs.com/
*
* -----------------------------------------------------------------------------
*
**/

@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1270px) {
	.Project_clasicSliderSect .prev {
		left: 0%;
	}

	.Project_clasicSliderSect .next {
		right: 0%;
	}
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1199px) {
	.gamfi-header-section .header-menu ul li + li {
		margin-left: 25px;
	}
	.gamfi-about-secion .game-price-item .price-details h3 {
		font-size: 24px;
	}
	.gamfi-about-secion .game-price-item {
		padding: 30px;
	}
	.gamfi-previous-section .previous-item .previous-chaining img {
		margin-right: 60px;
	}
	.gamfi-previous-section .previous-item .previous-raise .progress-inner {
		width: 140px;
	}
	.gamfi-previous-section .previous-item .previous-raise span {
		margin-right: 15px;
	}
	.gamfi-project-section .project-item {
		padding-left: 20px;
		padding-right: 20px;
	}
	.gamfi-project-section .project-item .heading-title h4:before {
		left: -21px;
	}
	.gamfi-project-section .project-item h4 {
		font-size: 20px;
	}
	.gamfi-tokenomics-section .pr-63,
	.gamfi-tokenomics-section .pr-63,
	.gamfi-tokenomics-section .pr-60 {
		padding-right: 40px;
	}
	.gamfi-calendar-list .calender-date {
		padding: 15px;
		width: 13%;
	}
	.gamfi-previous-section .previous-mainmenu .menu-list.date-menu {
		padding: 0;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form input[type="text"] {
		width: 330px;
	}
	.igo-rankging-table-list tr th,
	.igo-rankging-table-list tr td {
		width: 12.28%;
		padding: 20px 12px;
	}
	.igo-rankging-table-list tr th:nth-child(2),
	.igo-rankging-table-list tr td:nth-child(2) {
		width: 28%;
	}
	.igo-rankging-table-list tr .image-row {
		padding-top: 14.5px;
		padding-bottom: 14.5px;
	}
	.gamfi-farming-table tr th,
	.gamfi-farming-table tr td {
		width: 15.666%;
	}
	.gamfi-farming-table tr th:nth-child(5) {
		width: 24.666%;
	}
	.gamfi-farming-content .expeand-centent .farm-form-list input {
		padding: 7px 10px;
		font-size: 14px;
	}
	.project-summary-menu {
		padding: 48px 30px 15px;
	}
	.project-summary-menu .nav button {
		margin-bottom: 15px;
	}
	.project-details-conent .game-price-inner .image-icon img {
		max-width: 80px;
	}
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.price-inner
		.image-icon {
		margin-right: 15px;
	}
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.allocation-max
		.allocation {
		margin-top: 55px;
	}
	.project-details-conent .game-price-item .price-counter {
		margin-bottom: 26px;
	}
	.project-details-conent table td {
		padding: 17px 15px;
		font-size: 15px;
	}
	.project-details-conent table .table-header th {
		padding: 17px 20px;
	}
	.project-details-conent table .table-header th:nth-child(2) {
		padding-left: 30px;
	}
	.project-details-conent .project-service {
		padding: 50px 70px 25px;
	}
	.project-meta-item .meta-counter,
	.project-meta-item .meta-counter:first-child,
	.project-meta-inner .meta-header,
	.project-details-conent .project-item {
		padding: 35px 25px;
	}
	.project-meta-item .meta-counter {
		padding-top: 0;
	}
	.project-details-conent .project-item .date-listing li label {
		padding: 8px 12px;
		font-size: 15px;
	}
	.project-details-conent
		.project-item
		.project-form-list
		.balance-form-area
		input[type="submit"] {
		padding-left: 15px;
		padding-right: 15px;
		width: 128px;
		font-size: 15px;
	}
	.participat-information .project-item h4 {
		font-size: 20px;
	}
	.participat-information .project-item h3 {
		font-size: 24px;
	}
	.gamfi_V2_hero_Left h2 {
		font-size: 43px;
	}
	.gamfi-project-section
		.project-item
		.heading-title.heading-titleV2
		.price-counter:before {
		left: -20px;
	}

	.Tier_System_Headings ul li,
	.Tier_System_Items ul li {
		margin-right: 25px !important;
	}
	.Tier_System_Headings ul li.WeightHeadings,
	.Tier_System_Items ul li.WeightItem {
		width: 85px;
		margin-right: 0px !important;
	}

	/*New CSS*/
	.blog-detail-conent .blog-area .blog-description .des {
		font-size: 16px;
	}
	.blog-detail-conent .blog-area .blog-description .blockquote .des-active {
		font-size: 18px;
	}
	.blog-detail-conent .blog-area .Tokenomics-item .project-service {
		margin-right: 0;
		padding: 45px 0 20px 30px;
	}
	.blog-detail-conent .about-blog p,
	.blog-detail-conent .blog-description p,
	.blog-detail-conent .igo-blog p {
		font-size: 13px;
	}
	.blog-detail-conent .blog-area .igo-blog ul li span {
		font-size: 14px;
	}
	.blog-detail-conent .post-item .post-shade h4,
	.blog-detail-conent .blog-description .des-heading h4,
	.blog-detail-conent .blog-area .igo-blog h4,
	.blog-detail-conent .about-blog h4,
	.blog-detail-conent .blog-area .form-item .form-heading h4,
	.blog-detail-conent .tag-menu h4,
	.blog-detail-conent .comment-item h4 {
		font-size: 20px;
	}
	.newsletter-item .dsc,
	.blog-detail-conent
		.blog-area
		.comment-item
		.blog-comment
		.blog-replay
		.replay-text
		p {
		font-size: 15px;
	}
	.post-item .recent-content,
	.tag-menu ul li {
		display: block;
	}
	.newsletter-item h4 {
		font-size: 15px;
	}
	.newsletter-item.hover-shape-inner {
		padding: 30px 25px;
	}
	.newsletter-item form input {
		padding: 15px 10px;
	}
	.blog-detail-conent .blog-area .blog-description .des {
		margin-bottom: 15px;
	}
	.blog-detail-conent .about-blog p,
	.blog-detail-conent .blog-description p,
	.blog-detail-conent .igo-blog p {
		margin-bottom: 35px;
	}
	.blog-detail-conent .blog-area .form-item .input-submit {
		margin-bottom: 30px;
	}
	.newsletter-item.hover-shape-inner .hover_shape1 {
		left: 12%;
	}
	.newsletter-item.hover-shape-inner .hover_shape2 {
		left: 59.5%;
	}
	.newsletter-item.hover-shape-inner .hover_shape3 {
		left: 34%;
	}
	.blog-detail-conent .blog-area .blog-description .des {
		font-size: 15px;
	}
	.latest-details-content {
		padding-bottom: 80px;
	}
	.latest-details-content .latest-item .latest-text .sec-heading p {
		font-size: 14px;
	}
	.blog-detail-conent .blog-area .about-blog .blog-contact .next-item {
		/* margin-left: ; */
	}
	.latest-blog-area .latest-text h4 {
		font-size: 19px;
	}
	.megaMenuLeftSect {
		padding-right: 20px;
	}

	.megaMenuRightSect {
		max-width: 700px;
	}
	.HomeMenuListHeadings h2 {
		font-size: 12px;
	}
	.HomeMenuList ul li a {
		font-size: 12px !important;
	}
	.megaMenuRightSect .Menu_column {
		max-width: 180px;
	}

	.menuHeadings h2 {
		font-size: 12px;
	}
	.gamfi-header-section .header-menu ul li .Menu_column ul li a {
		font-size: 12px;
	}
	.Menu_column ul li span {
		font-size: 8px;
		height: 14px;
	}
}

@media (max-width: 1199px) {
	.Gamfi_Header_contentright {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
	.Gamfi_Header_ProgressBar .progress {
		margin-left: 0px;
		margin-top: 15px;
	}
	.RoadmapListSect ul li {
		font-size: 15px;
	}

	.slick-prev {
		left: 25%;
		top: -26%;
	}

	.slick-next {
		left: 30%;
		top: -30%;
	}
	.TokenomicsContentsLeftCustom {
		margin-left: 80px;
	}
	.TokenomicsTable table tr td {
		font-size: 14px;
		padding: 15px 18px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.gamfi-project-section .project-item .dsc {
		font-size: 13px;
	}
	.gamfi-previous-section .previous-item .previous-raise span {
		font-size: 14px;
	}
	.gamfi-calendar-list .calender-date > ul > li {
		max-width: 24px;
	}
	.gamfi-farming-content .default-centent {
		padding: 10px;
	}
	.project-details-conent .game-price-item .targeted-raise .targeted-raise {
		top: -8px;
	}
	.RodmapLeftConetent {
		padding-right: 30px;
	}
	.RodmapRightConetent {
		padding-left: 30px;
	}
	.RodmapConetentbox {
		max-width: 415px;
		padding: 20px 25px;
	}
	.RodmapConetentbox1 {
		min-height: 15px;
	}
	.RodmapConetentbox2 {
		min-height: 185px;
	}
	.RodmapConetentbox3 {
		min-height: 158px;
	}
	.RodmapConetentbox4 {
		min-height: 230px;
		background: url(../images/kycimg/roadMapcpntentBg5.svg);
		background-position: left;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox5 {
		min-height: 260px;
		background: url(../images/kycimg/roadMapcpntentBg3.svg);
		background-position: right;
		background-size: contain;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox6 {
		background: url(../images/kycimg/roadMapcpntentBg7.svg);
		min-height: 230px;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.RodmapConetentbox7 {
		min-height: 185px;
	}
	.roadmap2021Divider {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		min-height: 775px;
		top: 0px;
		width: 50px;
		background: url(../images/kycimg/Roadmap2021.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.roadmap2022Divider {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		min-height: 675px;
		bottom: -65px;
		width: 50px;
		background: url(../images/kycimg/Roadmap2022.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.TvlRightCardHeadings h3 {
		font-size: 18px;
	}
	.TvlRightCardContent ul li {
		font-size: 14px;
	}
	.Article_Details h2,
	.Article_Details h2 span {
		font-size: 12px;
	}
	.Article_Details h3 a {
		font-size: 18px;
	}
	.Article_Details p {
		font-size: 14px;
	}
	.Article_Details a {
		font-size: 12px;
	}
	.Latest_Articles_Card {
		min-height: 495px;
	}
	.Article_Details2 h4,
	.Article_Details2 h4 a {
		font-size: 24px;
		line-height: 35px;
	}
	.Article_Details2 h4 a strong {
		font-size: 24px;
		line-height: 35px;
	}
	.Article_Details2 h5 {
		font-size: 14px;
	}

	.FAQ_Screen_Tab {
		padding: 34px 20px;
	}
	.FAQ_Screen_Tab ul li {
		font-size: 15px;
	}
	.FAQ_Screen_Tab ul li.active,
	.FAQ_Screen_Tab ul li:hover {
		font-size: 15px;
	}

	.ProjectDetails_TeamMembersCard h5 {
		font-size: 14px;
	}
	.ProjectDetails_TeamMembersCard h6 {
		font-size: 12px;
	}
	.TeamMembers_Img {
		width: 45%;
	}
	.ProjectDetailsRoadMapContent ul li {
		font-size: 15px;
	}

	.ProjectSammeryTabsSect button a {
		font-size: 15px;
	}

	.gamfi-previous-section.ProjectClasic_PreviousSection
		.previous-item
		.previous-gaming
		.previous-price
		h4 {
		font-size: 18px;
	}
	.gamfi-previous-section.ProjectClasic_PreviousSection
		.previous-item
		.previous-gaming
		.previous-price
		.dsc {
		font-size: 14px;
	}

	.ProjectClasicSlider_TextSect h3 a {
		font-size: 27px;
	}
	.ProjectClasicSlider_TextSect p {
		font-size: 14px;
	}

	.First_Survivor_CityRightSection p {
		font-size: 15px;
		margin-top: 30px;
		margin-bottom: 25px;
	}
	.ProjectDetails2_ScheduleTableHeadings ul li,
	.ProjectDetails2_ScheduleTablebody ul li,
	.ProjectDetails2_AllocationsTableHeadings ul li,
	.ProjectDetails2_AllocationsTableBody ul li {
		font-size: 14px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.RodmapLeftConetent {
		padding-right: 30px;
	}
	.RodmapRightConetent {
		padding-left: 30px;
	}
	.RodmapConetentbox {
		max-width: 305px;
		padding: 20px 25px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	}
	.RodmapConetentbox ul li {
		font-size: 14px;
	}
	.RodmapConetentbox1 {
		min-height: 158px;
		background: url(../images/kycimg/roadMapcpntentBg1.svg);
		background-position: right;
		background-size: cover;
	}
	.RodmapConetentbox2 {
		min-height: 185px;
		background: url(../images/kycimg/roadMapcpntentBg5.svg);
		background-position: left;
		background-size: cover;
	}
	.RodmapConetentbox3 {
		min-height: 158px;
		background: url(../images/kycimg/roadMapcpntentBg2.svg);
		background-position: right;
		background-size: cover;
	}
	.RodmapConetentbox4 {
		min-height: 216px;
		background: url(../images/kycimg/roadMapcpntentBg6.svg);
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox5 {
		min-height: 260px;
		background: url(../images/kycimg/roadMapcpntentBg3.svg);
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox6 {
		background: url(../images/kycimg/roadMapcpntentBg7.svg);
		min-height: 230px;
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox7 {
		min-height: 185px;
		background: url(../images/kycimg/roadMapcpntentBg4.svg);
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.Article_Details h2,
	.Article_Details h2 span {
		font-size: 11px;
	}
	.Article_Details h3 a {
		font-size: 20px;
	}
	.Article_Details p {
		font-size: 14px;
	}
	.Article_Details a {
		font-size: 12px;
	}

	.Latest_Articles_Card {
		min-height: 495px;
	}
	.Article_Details2 h4,
	.Article_Details2 h4 a {
		font-size: 17px;
		line-height: 35px;
	}
	.Article_Details2 h4 a strong {
		font-size: 17px;
		line-height: 35px;
	}
	.Article_Details2 h5 {
		font-size: 14px;
	}

	.FAQ_Screen_Tab_Sect {
		padding-right: 0px;
	}
	.FAQ_Screen_Tab {
		padding: 25px 16px;
	}

	.FAQ_Screen_Tab ul li,
	.FAQ_Screen_Tab ul li.active,
	.FAQ_Screen_Tab ul li:hover {
		font-size: 13px;
	}
	.FAQ_Screen_Tab ul li span {
		margin-left: 10px;
		width: 45px;
	}
	.FAQ_Screen_Tab_Content {
		padding-left: 0px;
	}

	.FAQContnetSect .accordion {
		font-size: 18px;
	}

	.ProjectDetailsRoadMapContent ul li {
		line-height: 158%;
		font-size: 14px;
		margin-bottom: 16px;
	}
	.ProjectDetailsRoadMapContent ul li span {
		min-width: 14px;
		max-width: 14px;
	}

	.project-summary-menu {
		padding: 30px 13px;
	}

	.ProjectSammeryTabsSect button a {
		font-size: 14px;
	}
	.ProjectSammeryTabsSect button a img {
		width: 40px;
	}

	.gamfi-previous-section.ProjectClasic_PreviousSection
		.previous-item
		.previous-gaming
		.previous-price
		h4 {
		font-size: 16px;
	}

	.gamfi-previous-section.ProjectClasic_PreviousSection
		.previous-item
		.previous-gaming
		.previous-price
		.dsc {
		font-size: 12px;
	}

	.gamfi-previous-section.ProjectClasic_PreviousSection
		.previous-item
		.previous-EndsIn.list3Custom
		span {
		font-size: 10px;
	}

	.gamfi-previous-section .previous-item .previous-raise.list4Custom span {
		font-size: 10px;
	}
	.ProjectClasicCustomProgressSect p {
		font-size: 10px;
	}

	.liveProoject_Headings h2 {
		font-size: 30px;
	}
	.liveProoject_Headings {
		max-width: 285px;
	}
	.ProjectClasicSliderBG {
		padding: 30px;
	}
	.ProjectClasicSlider_ImgAndTitleSect img {
		max-width: 50px;
	}
	.ProjectClasicSlider_TextSect {
		margin-left: 15px;
	}
	.ProjectClasicSlider_TextSect h3 {
		margin-bottom: 0px;
	}
	.ProjectClasicSlider_TextSect h3 a {
		font-size: 19px;
	}
	.ProjectClasicSlider_TextSect p {
		font-size: 11px;
	}
	.ProjectClasicSlider_RightTimer h2 {
		font-size: 11px;
	}
	.ProjectClasicSlider_RightTimer .price-counter .timer ul li {
		font-size: 19px;
	}
	.ProjectClasicSlider_RiseCeontent h2,
	.Access_AllocationText h2 {
		font-size: 12px;
	}
	.ProjectClasicSlider_RiseCeontent span,
	.Access_AllocationText span {
		font-size: 10px;
	}
	.ProjectClasicSlider_RightTimer h2 {
		margin-bottom: 10px;
	}
	.ProjectClasicSlider_RightTimer {
		margin-bottom: -5px;
	}
	.ProjectClasicSliderSocialLinks li a i {
		font-size: 18px;
	}
	.ProjectClasicSliderBG {
		min-height: 350px;
	}
	.Project_clasicSliderSect .prev {
		left: -7%;
	}
	.Project_clasicSliderSect .next {
		right: -7%;
	}
	.Project_clasicSliderSect .prev img,
	.Project_clasicSliderSect .next img {
		height: 30px;
	}

	.Project_clasic_2Container_RightHeadings h2 {
		margin-bottom: 5px;
	}

	.Project_clasic_2Container_RightHeadings h2 a {
		font-size: 22px;
	}

	.Project_clasic_2Container_RightHeadings p {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.Project_clasic_2Container_RightHeadings span {
		height: 30px;
		width: 30px;
	}

	.TotalRisedProgressHeadings h3,
	.TotalRisedProgressHeadings p {
		font-size: 12px;
	}
	.TotalRisedProgresSaleAirdropSect p {
		font-size: 12px;
	}
	.Access_Allocation_ParticipantsSect ul li span,
	.Access_Allocation_ParticipantsSect ul li p {
		font-size: 12px;
	}
	.Project_clasic_2ContainerSocialSect ul {
		max-width: 200px;
	}
	.Project_clasic_2ContainerSocialSect ul li a i {
		font-size: 16px;
	}
	.Project_clasic_2_ContainerLeft {
		min-height: 335px;
	}
	.Access_Allocation_ParticipantsSect {
		margin-top: 10px;
	}
	.Project_clasic_2ContainerSocialSect {
		margin-top: 35px;
	}
	.ProjectClasic2_Timer {
		width: 210px;
	}
	.Project_clasic_2_LeftContentImg {
		height: 50px;
		width: 50px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul {
		max-width: 140px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul li {
		font-size: 13px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul li span {
		font-size: 10px;
	}

	.First_Survivor_CityLeft_Timer {
		width: 245px;
		height: 40px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul {
		width: 165px;
		padding-top: 8px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul li {
		font-size: 17px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul li span {
		font-size: 10px;
	}
	.First_Survivor_CityLeftSection .ChainImg {
		width: 40px;
	}
	.First_Survivor_CityRight_ProjectName {
		margin-left: 20px;
	}
	.First_Survivor_CityRight_ProjectName h2 {
		margin-bottom: 0px;
	}
	.First_Survivor_CityRight_ProjectName h2 a {
		font-size: 23px;
	}
	.First_Survivor_CityRight_ProjectName span {
		font-size: 13px;
	}
	.First_Survivor_CityRight_ProjectImg {
		height: 70px;
		width: 70px;
	}

	.First_Survivor_CityRightSection p {
		font-size: 13px;
		line-height: 26px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.First_Survivor_CityRight_Button button {
		font-size: 13px;
		height: 40px;
	}
	.First_Survivor_CityLeftSection .TotalRisedProgressSect {
		margin-top: 100px;
	}

	.PoolAndTokenInfo_List ul li p.PoolAndTokenInfo_List_left,
	.PoolAndTokenInfo_List ul li p.PoolAndTokenInfo_ListRight {
		font-size: 13px;
	}
	.PoolAndTokenInfo_List ul li p.PoolAndTokenInfo_ListRight span {
		margin-right: 15px;
	}

	.V3Project_clasic_2Container_RightSect {
		padding: 15px 22px 22px 0px;
	}
}

@media only screen and (min-width: 992px) {
	.gamfi-header-section .gamfi-btn-area ul .nav-expander {
		display: none;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.breadcrumbs-form .gamfi-navigation {
		margin-left: 20px;
	}
	.kycForm .UploadDocSect ul li h4 {
		font-size: 11px;
	}

	.kycPersonalinfoForm form .InputNameSect,
	.kycPersonalinfoForm form .InputAddressSect,
	.kycPersonalinfoForm form .InputCitySect {
		flex-direction: column;
		align-items: flex-start;
	}
	.kycPersonalinfoForm form .InputNameSect .FirstNameFild,
	.kycPersonalinfoForm form .InputNameSect .LastNameFild,
	.kycPersonalinfoForm form .InputAddressSect .Address1Fild,
	.kycPersonalinfoForm form .InputAddressSect .Address2Fild,
	.kycPersonalinfoForm form .InputCitySect .CityFild,
	.kycPersonalinfoForm form .InputCitySect .PostCodeFild {
		width: 100%;
	}
	.roadmap2021Divider {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		min-height: 750px;
		top: 0px;
		width: 50px;
		background: url(../images/kycimg/Roadmap2021.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.roadmap2022Divider {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		min-height: 650px;
		bottom: -10px;
		width: 50px;
		background: url(../images/kycimg/Roadmap2022.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.buyTokenlogos ul {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.buyTokenlogos ul li {
		width: 47%;
		margin-right: 0px;
		margin-bottom: 26px;
	}

	.Key_Points_Item_Text h3 {
		font-size: 16px;
	}
	.Key_Points_Item_Text p {
		font-size: 12px;
		line-height: 158%;
	}

	.TvlRightCardHeadings h3 {
		font-size: 16px;
	}
	.TvlRightCardContent ul li {
		font-size: 12px;
	}
	.TvlHeadings h3 {
		font-size: 26px;
	}
	.TvlHeadings h2 {
		font-size: 20px;
	}
	.TvlList ul li {
		font-size: 14px;
	}

	.tierV2ColHeadings h2 {
		font-size: 30px;
	}
	.tierV2ColHeadings h3 {
		font-size: 17px;
	}

	.TiedV2CollBody ul li h4 {
		font-size: 13px;
	}
	.TiedV2CollBody ul li h5 {
		font-size: 11px;
	}

	.V1_tokenomics_content_ListProgress {
		height: 40px;
	}
	.V1_tokenomics_content_ListText h4 {
		font-size: 13px;
	}
	.V1_tokenomics_content_ListText h5 {
		font-size: 13px;
		line-height: 20px;
	}

	.gamfi-tokenomics-section .tokenomics-counter h5 {
		font-size: 14px;
	}
}

@media only screen and (max-width: 991px) {
	.gamfi-header-section .heaader-inner-area ul li.buy-token,
	.gamfi-header-section .header-menu {
		display: none;
	}
	.sc-banner .banner-content .banner-title {
		margin: 0 0 20px;
		font-size: 40px;
	}
	.banner-btn {
		padding: 14px 30px;
	}
	.gamfi-about-secion .game-price-item .game-price-inner .price-inner,
	.gamfi-about-secion .game-price-item .game-price-inner {
		display: block !important;
	}
	.gamfi-about-secion .allocation-max,
	.gamfi-about-secion .total-price,
	.gamfi-about-secion .targeted-raise {
		width: 100%;
	}
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.allocation-max
		.allocation {
		margin-top: 35px;
	}
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.targeted-raise
		.targeted-raise,
	.gamfi-about-secion .game-price-item .game-price-inner .all-raise,
	.gamfi-about-secion .game-price-item .game-price-inner .price-inner {
		text-align: center !important;
	}
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.targeted-raise
		.targeted-raise,
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.price-inner
		.image-icon {
		margin-bottom: 10px;
	}
	.gamfi-about-secion
		.game-price-item
		.game-price-inner
		.price-inner
		.image-icon {
		margin-right: 0;
	}
	.banner-slider-inner .timer {
		max-width: 400px;
		margin: 0 auto 20px;
	}
	.sc-banner {
		padding: 150px 0 200px;
	}
	.participate-item .number-image {
		flex: 0 0 15%;
	}
	.apply-heading h2,
	.gamfi-footer-section .footer-cta-area .title,
	.sec-heading .title {
		font-size: 30px;
	}
	.gamfi-project-section {
		padding-top: 75px;
		padding-bottom: 45px;
	}
	.gamfi-previous-section .previous-item {
		padding: 20px;
	}
	.gamfi-previous-section .previous-item .previous-gaming .previous-price h4 {
		font-size: 18px;
		margin-bottom: 4px;
	}
	.gamfi-previous-section .previous-item .previous-chaining img {
		margin-right: 20px;
	}
	.gamfi-previous-section .previous-item .previous-gaming img {
		width: 50px;
	}
	.gamfi-previous-section .previous-mainmenu ul .list1 {
		width: 31.5%;
	}
	.gamfi-tokenomics-section .tokenomics-menu,
	.gamfi-tokenomics-section .pr-63,
	.gamfi-tokenomics-section .pr-63,
	.gamfi-tokenomics-section .pr-60 {
		padding-right: 30px;
	}
	.gamfi-footer-section .footer-listing .footer-icon-list i {
		width: 50px;
		height: 50px;
		line-height: 50px;
		margin: 0 8px;
		font-size: 18px;
	}
	.gamfi-footer-section .footer-area {
		padding-top: 220px;
	}
	.gamfi-footer-section .footer-cta-area {
		padding: 60px 20px;
	}
	.gamfi-team-section .team-item img {
		margin-bottom: 30px;
	}
	.gamfi-about-secion .game-price-item .price-details h3 {
		margin-bottom: 5px;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form {
		justify-content: flex-start;
	}
	.gamfi-footer-section .copyright-area .dsc {
		margin-bottom: 25px;
	}
	.gamfi-previous-section .previous-mainmenu .menu-list .date-list {
		width: 12%;
	}
	.gamfi-calendar-list {
		justify-content: flex-start;
	}
	.gamfi-calendar-list .calender-date {
		width: 24%;
	}
	.project-menu-area ul li a,
	.project-menu-area ul li .tab-link {
		padding: 14px 13px;
	}
	.project-menu-area ul li:last-child {
		border: none;
	}
	.gamfi-farming-table tr th:first-child,
	.gamfi-farming-table tr td:first-child {
		width: 14.666%;
	}
	.gamfi-farming-table tr th:nth-child(5) {
		width: 35.666%;
	}
	.gamfi-farming-content .list-1 {
		flex: 0 0 32%;
		width: 32%;
	}
	.project-menu-area .al-access-menu > li:after,
	.project-menu-area .al-access-menu > li:before {
		top: 22px;
	}
	.gamfi-farming-content .list-content {
		flex: 0 0 100%;
		width: 100%;
		padding-bottom: 10px;
	}
	.gamfi-farming-content .default-centent,
	.gamfi-farming-content
		.expeand-bottom-content
		.expeand-centent
		.expeand-farm {
		display: block !important;
	}
	.gamfi-farming .farm-form-list {
		width: 100%;
	}
	.gamfi-farming-content .default-centent {
		padding-top: 28px;
	}
	.gamfi-farming-table table {
		display: block;
		width: 100%;
		overflow-x: auto;
	}
	.gamfi-farming .farm-form-list {
		margin-bottom: 30px;
	}
	.gamfi-farming .farm-form-list:last-child {
		margin-top: 40px;
		margin-bottom: 0;
	}
	.gamfi-farming .farm-form-list .farm-form {
		margin-bottom: 0;
	}
	.igo-rankging-table-list tr th:nth-child(2),
	.igo-rankging-table-list tr td:nth-child(2) {
		min-width: 225px;
	}
	.igo-rankging-table-list tr th:last-child,
	.igo-rankging-table-list tr td:last-child {
		width: 10.28%;
	}
	.igo-rankging-table-list tr th,
	.igo-rankging-table-list tr td {
		padding: 20px 5px;
	}
	.igo-rankging-table-list tr .image-row img {
		margin-right: 5px;
	}
	.igo-rankging-table-list tr {
		display: flex;
	}
	.gamfi-previous-section .previous-mainmenu ul li,
	.igo-rankging-table-list tr th {
		font-size: 14px;
	}

	.leaderboard-table-list tr th,
	.leaderboard-table-list tr td {
		padding: 15px 8px;
		font-size: 15px;
	}
	.gamfi-previous-section .previous-item .previous-gaming .previous-price .dsc {
		font-size: 14px;
	}
	.project-details-conent .game-price-item .price-counter {
		max-width: 300px;
		margin: 0 auto 20px;
	}
	.project-details-conent .banner-bottom-content .social-icon-list li {
		margin-left: 0;
	}
	.project-details-conent .project-item {
		padding: 35px 20px;
	}
	.project-details-conent .project-service {
		display: block;
	}
	.project-details-conent .project-service .project-image {
		margin: 0 0 40px;
	}
	.project-summary-menu .nav button img {
		display: none;
	}
	.project-details-conent .project-service {
		padding: 50px 40px 25px;
	}
	.project-details-conent .banner-bottom-content .social-icon-list li i,
	.project-details-conent .game-price-item .text-content {
		font-size: 15px;
	}
	.project-details-conent .readon {
		padding: 8px 15px;
	}
	.project-details-conent .project-item .project-info {
		margin-bottom: 0;
	}
	.participat-information .project-item .date-listing {
		padding-top: 20px;
	}
	.project-details-conent .project-item ul.date-listing {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.Gamfi_Header_contentright {
		display: block;
		margin-top: 10px;
	}

	/*New CSS*/
	.blog-detail-conent {
		padding-bottom: 80px;
	}
	.blog-detail-conent .blog-area .blog-description .des {
		font-size: 18px;
	}
	.blog-detail-conent .about-blog p,
	.blog-detail-conent .blog-description p,
	.blog-detail-conent .igo-blog p {
		font-size: 15px;
	}
	.blog-detail-conent .blog-area .blog-description .blockquote .des-active {
		font-size: 20px;
	}
	.post-item .recent-content {
		display: flex;
	}
	.gamfi-breadcrumbs-section.blog-breadcrumbs .sec-heading h1 {
		font-size: 50px;
	}
	.blog-detail-conent .blog-area .blog-description .des {
		font-size: 16px;
	}
}

@media (max-width: 991px) {
	.gamfi-breadcrumbs-section .breadcrumbs-form.breadcrumbsprogress_BarCustom {
		display: flex;
		align-items: center;
		justify-content: flex-start !important;
		margin-top: 25px;
	}
	.Gamfi_Header_ProgressBar .progress {
		margin-left: 0px;
		margin-top: 10px;
		width: 400px;
	}
	.kycForm .UploadDocSect ul li h4 {
		width: 74%;
		margin: auto;
	}

	.gamfi_header_V2MenuList ul.v2MainMenuList {
		display: none;
	}
	.gamfi_V2_hero_Left h2 {
		font-size: 45px;
		line-height: 158%;
	}
	.gamfi_V2_hero_Left p {
		font-size: 17px;
	}
	.gamfi_V2_hero_Btns .VIEW_Projects_Btn,
	.gamfi_V2_hero_Btns .Apply_Project_Btn {
		width: 150px;
		height: 50px;
	}
	.gamfi_V2_hero_Btns .VIEW_Projects_Btn .btn-text,
	.gamfi_V2_hero_Btns .Apply_Project_Btn .btn-text {
		font-size: 14px;
	}
	.TokenomicsListSect ul {
		margin-right: 50px;
	}
	.Multi_Chain_Support_right_list ul {
		margin-right: 30px;
	}
	.Multi_Chain_Support_right_list ul li {
		font-size: 15px;
	}
	.gamfisubscriveSect input {
		max-width: 390px;
		height: 52px;
	}

	.ProjectV2_TabBtns ul li button {
		width: 125px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px !important;
	}
	.ProjectV2_TabBtns ul li button .btn-text {
		font-size: 13px;
	}

	.RoadmapTabBtns ul li button {
		width: 100px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px !important;
	}
	.RoadmapTabBtns ul li button .btn-text {
		font-size: 13px;
	}

	.Tier_System_Items ul li span {
		max-width: 18px;
		min-width: 18px;
	}
	.Tier_System_Items ul li strong {
		font-size: 12px;
	}
	.Tier_System_Headings ul li,
	.Tier_System_Items ul li {
		font-size: 12px;
		margin-right: 10px !important;
	}
	.Tier_System_Items ul li.tiersItem {
		width: 113px;
	}
	.Tier_System_Items ul li.StakingItem {
		width: 125px;
	}
	.Tier_System_Items ul li.allocationItem {
		width: 150px;
	}
	.Tier_System_Items ul li.RequirementsItem {
		width: 125px;
	}
	.Tier_System_Headings ul li.WeightHeadings,
	.Tier_System_Items ul li.WeightItem {
		width: 60px;
	}

	.RoadmapListSect ul li {
		font-size: 12px;
	}
	.RoadmapListSect ul li span {
		min-width: 10px;
		max-width: 10px;
	}

	.slick-prev {
		left: 28%;
		top: -20%;
	}

	.slick-next {
		left: 34%;
		top: -23%;
	}
	.sc_banner_V3_left h2 {
		font-size: 40px;
		line-height: 45px;
	}
	.sc_banner_V3_left p {
		font-size: 15px;
	}
	.sc_banner_V3_right ul li h3 {
		font-size: 12px;
	}
	.sc_banner_V3_right ul li h4 {
		font-size: 16px;
	}
	.GamfiV3_ProjectPoolsBtn li button {
		width: 120px;
		height: 40px;
		font-size: 14px;
	}
	.How_to_Participate_right_Card_number {
		min-width: auto;
	}

	.TokenomicsContentsLeftCustom {
		margin-left: 20px;
	}

	.ProjectDetails2_ScheduleTable,
	.ProjectDetails2_AllocationsTable {
		overflow-y: scroll;
		height:2000px;
	}

	.ProjectDetails2_ScheduleTableHeadings,
	.ProjectDetails2_ScheduleTablebody,
	.ProjectDetails2_AllocationsTableHeadings,
	.ProjectDetails2_AllocationsTableBody {
		min-width: 920px;
	}

	.ProjectDetails2_ScheduleTableHeadings ul li,
	.ProjectDetails2_ScheduleTablebody ul li,
	.ProjectDetails2_AllocationsTableHeadings ul li,
	.ProjectDetails2_AllocationsTableBody ul li {
		font-size: 14px;
	}

	.latest-details-content .latest-content {
		padding-right: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.sc-banner .banner-content .banner-title {
		font-size: 30px;
	}
	.gamfi-footer-section .footer-cta-area .title,
	.sec-heading .title {
		font-size: 26px;
	}
	.gamfi-previous-section .previous-mainmenu ul {
		padding: 0;
	}
	.project-menu-area .project-right-menu ul li,
	.gamfi-previous-section .previous-item,
	body .gamfi-previous-section .previous-mainmenu ul li {
		display: block;
	}
	body .gamfi-previous-section .previous-mainmenu ul li {
		padding: 0 0 14px;
		left: 0 !important;
	}
	.project-menu-area .project-right-menu ul li:first-child {
		border: none;
	}
	.gamfi-previous-section .previous-item .previous-raise,
	.gamfi-previous-section .previous-item .previous-chaining,
	.gamfi-previous-section .previous-item .previous-gaming {
		width: 100%;
	}
	body .gamfi-previous-section .previous-mainmenu ul li {
		width: 100% !important;
	}
	.gamfi-previous-section .previous-item .previous-chaining {
		margin: 20px 0;
	}
	.gamfi-header-section .white-btn {
		padding: 9px 20px 8px;
	}
	.gamfi-form-content .address-form input {
		padding: 10px 15px;
	}
	.gamfi-form-content .address-form .input-button i {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.gamfi-footer-section .footer-cta-area .footer-link input[type="submit"] {
		padding: 14px 30px;
	}
	.gamfi-footer-section .footer-cta-area .footer-link input[type="email"] {
		width: 290px;
	}
	.gamfi-calendar-list .calender-date {
		width: 32%;
	}
	.project-menu-area {
		display: block !important;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form input[type="text"] {
		width: 300px;
	}
	.gamfi-farming .project-menu-area ul button {
		width: 100%;
		text-align: left;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form,
	.gamfi-farming .project-menu-area ul button,
	.gamfi-farming .project-menu-area ul {
		display: block;
	}
	.gamfi-farming .form-switch {
		padding: 8px 12px;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form input[type="text"] {
		width: 100%;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form input[type="text"] {
		margin: 0 0 20px;
	}
	body .gamfi-farming-table tr th,
	body .gamfi-farming-table tr td {
		display: block;
		width: 100% !important;
		margin-bottom: 10px;
		padding: 0;
	}
	.gamfi-farming-content .expeand-centent .expeand-menu ul li {
		display: block;
		margin: 0 0 10px;
	}
	.leaderboard-table-list table,
	.igo-rankging-table-list table {
		display: block;
		width: 100%;
		overflow-x: auto;
	}
	.leaderboard-table-list tr {
		display: flex;
	}
	.igo-rankging-table-list tr th:nth-child(1),
	.igo-rankging-table-list tr td:nth-child(1) {
		min-width: 65px;
	}
	.leaderboard-table-list tr th:nth-child(3),
	.leaderboard-table-list tr td:nth-child(3) {
		min-width: 160px;
	}
	.leaderboard-table-list tr th:nth-child(2),
	.leaderboard-table-list tr td:nth-child(2) {
		min-width: 140px;
	}
	.leaderboard-table-list tr th:nth-child(1),
	.leaderboard-table-list tr td:nth-child(1),
	.leaderboard-table-list tr th:nth-child(4),
	.leaderboard-table-list tr td:nth-child(4) {
		min-width: 100px;
	}
	.igo-rankging-table-list tr th:last-child,
	.igo-rankging-table-list tr td:last-child {
		min-width: 80px;
	}
	.igo-rankging-table-list tr td:nth-child(1) {
		padding-left: 8px;
	}
	.project-details-conent .banner-bottom-content .social-icon-list li i {
		padding-left: 5px;
	}
	.project-details-conent .project-value-inner .project-value-image {
		margin-top: 15px;
	}
	.project-meta-item .meta-counter h3,
	.project-details-conent .project-item h3 {
		font-size: 24px;
		margin-bottom: 5px;
	}
	.project-meta-inner .meta-header p {
		margin-bottom: 0;
	}
	.breadcrumbs-form .gamfi-navigation ul {
		justify-content: flex-start;
	}
	.loader_first .circular-spinner {
		left: 45%;
	}
	.GetInTouch_Contuct_Content {
		padding-top: 20px;
	}
	.GetInTouch_Contuct_Sect {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		max-width: 330px;
		width: 100%;
		padding-left: 0px;
		margin: auto;
		margin-top: 40px;
	}

	/*New CSS*/
	.blog-detail-conent .blog-area .Tokenomics-item .project-service {
		display: block !important;
		text-align: center;
		padding-left: 130px;
	}
	.blog-detail-conent
		.blog-area
		.Tokenomics-item
		.project-service
		.project-image
		img {
		margin-right: 140px;
		margin-bottom: 30px;
	}
	.blog-detail-conent .blog-area .comment-item .blog-comments {
		margin-right: 25px;
		left: 0;
	}
	.gamfi-breadcrumbs-section.blog-breadcrumbs .sec-heading h1 {
		font-size: 40px;
	}
	.blog-detail-conent .blog-area .blog-description .blockquote .des-active {
		font-size: 15px;
	}
	.blog-detail-conent .blog-area .comment-item .blog-comment .blog-replay {
		display: block;
	}
	.blog-detail-conent
		.blog-area
		.comment-item
		.blog-comment
		.blog-replay
		.replay-image
		img {
		margin-bottom: 15px;
		padding-left: 25px;
	}
	.gamfi-footer-section .footer-cta-area .title,
	.sec-heading .title {
		font-size: 22px;
	}
	.blog-detail-conent .blog-area .blog-description .des {
		font-size: 18px;
	}
	.latest-details-content .latest-blog-area .latest-image img {
		min-width: 100%;
	}
	.blog-detail-conent .blog-area .about-blog .blog-contact .previous-item {
		display: block;
	}
	.blog-detail-conent .blog-area .about-blog .blog-contact .next-item {
		display: block;
	}
	.blog-detail-conent .blog-area .comment-item .blog-comments {
		margin-right: 0;
		margin-left: 0;
	}

	.V1_tokenomics_content_List_Sect {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.V1_tokenomics_content_List {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.V1_tokenomics_content_ListText {
		min-width: 91px;
	}

	.V1_tokenomics_content_List_1,
	.V1_tokenomics_content_List_2,
	.V1_tokenomics_content_List_3,
	.V1_tokenomics_content_List_4,
	.V1_tokenomics_content_List_5,
	.V1_tokenomics_content_List_6,
	.V1_tokenomics_content_List_7 {
		width: 100%;
		flex-direction: row;
	}

	.V1_tokenomics_content_ListText h5 {
		margin-bottom: 0px;
	}

	.V1_tokenomics_content_ListProgress1 {
		width: 100px;
	}
	.V1_tokenomics_content_ListProgress2 {
		width: 120px;
	}
	.V1_tokenomics_content_ListProgress3 {
		width: 130px;
	}
	.V1_tokenomics_content_ListProgress4 {
		width: 160px;
	}
	.V1_tokenomics_content_ListProgress5 {
		width: 190px;
	}
	.V1_tokenomics_content_ListProgress6 {
		width: 200px;
	}
	.V1_tokenomics_content_ListProgress7 {
		width: 270px;
	}

	.V1_tokenomics_content_ListText {
		margin-bottom: 0px;
	}
	.V1_tokenomics_content_ListText h4,
	.V1_tokenomics_content_ListText h5 {
		font-size: 14px;
		line-height: 18px;
	}
	.V1_tokenomics_content_ListProgress {
		height: 40px;
	}

	.gamfi-previous-section .previous-mainmenu ul .list1.list1Custom {
		width: 31%;
	}

	.gamfi-previous-section .previous-mainmenu ul .list2.list2Custom,
	.gamfi-previous-section .previous-mainmenu ul .list3.list3Custom,
	.gamfi-previous-section .previous-mainmenu ul .list4.list4Custom,
	.gamfi-previous-section .previous-mainmenu ul .list5.list5Custom,
	.gamfi-previous-section .previous-item .previous-gaming.list1Custom,
	.gamfi-previous-section .previous-item .previous-chaining.list2Custom,
	.gamfi-previous-section .previous-item .previous-EndsIn.list3Custom,
	.gamfi-previous-section .previous-item .previous-raise.list4Custom,
	.gamfi-previous-section .previous-item .previousProgress.list5Custom {
		width: 100%;
	}

	.ProjectClasicCustomProgressSect .ProgressBg {
		max-width: 90%;
	}

	.ProjectClasicSlider_ImgAndTitleSect {
		flex-direction: column;
	}
	.ProjectClasicSlider_TextSect {
		margin-left: 0px;
		margin-top: 16px;
		text-align: center;
	}
	.ProjectClasicSlider_RightTimer h2 {
		text-align: center;
	}
	.ProjectClasicSlider_ClaimBtn {
		text-align: center;
		position: absolute;
		bottom: 110px;
		width: 100%;
		left: 0px;
	}
	.ProjectClasicSliderSocialLinks {
		margin: auto;
		margin-top: 130px;
	}

	.V3Project_clasic_2Container_RightSect {
		padding: 0px 30px 30px 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
	.kycForm .UploadDocSect ul li h4 {
		width: 80%;
		margin: auto;
		line-height: 158%;
		margin-top: 16px;
	}
	.kycForm .UploadDocSect .upload-btn-wrapper span {
		height: 60px;
		width: 60px;
	}
	.kycForm .UploadBtnBg {
		height: 110px;
	}
}

@media (max-width: 768px) {
	.Gamfi_Header_contentright {
		margin-top: 24px;
	}
	.kycCountryDropbox,
	.KycRadioBtn,
	.kycForm button {
		max-width: 100%;
	}
	.kycQrcodeSect {
		justify-content: center;
		margin-top: 40px;
	}
	.kycQrcode_content h2 {
		text-align: center;
	}
	.TeamDetailsImg {
		max-width: 100%;
		margin-bottom: 40px;
	}
	.TeamMembersInfo {
		padding-left: 0px;
	}
	.kycForm .UploadDocSect ul,
	.UploadDocCheckList,
	.NidNumberSect form input {
		max-width: 100%;
	}
	.kycForm {
		padding: 20px 0px;
	}
	.KycYourDocumentsSect {
		align-items: center;
		margin-top: 40px;
	}
	.KycYourDocumentsContent h3 {
		text-align: center;
	}
	.RoadMapSect {
		height: auto;
	}
	.RodmapConetentbox {
		margin-top: 50px;
	}
	.RodmapLeftConetent {
		justify-content: center;
		align-items: center;
		padding-right: 0px;
	}
	.RodmapRightConetent {
		justify-content: center;
		align-items: center;
		padding-left: 0px;
	}
	.Roadmap2022 {
		margin-top: 100px;
	}
	.roadmap2021Divider {
		display: none;
	}
	.roadmap2022Divider {
		display: none;
	}
	.Roadmap2021 {
		margin-top: 40px;
	}
	.gamfi_V2_hero_right {
		margin: auto;
		margin-top: 50px;
	}

	.Market_CapContent h2 {
		font-family: Russo One;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 45px;
		margin-bottom: 0px;
		color: rgba(255, 255, 255, 0.7);
	}

	.Market_CapContent h3 {
		font-family: Russo One;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 45px;
		text-transform: uppercase;
		color: #6d4afe;
		margin-bottom: 0px;
	}

	.TokenomicsListSect ul {
		margin-right: 80px;
	}
	.Multi_Chain_Support_right_list ul {
		margin-right: 60px;
	}
	.Multi_Chain_Support_Left_Sect {
		margin-bottom: 60px;
		text-align: center;
	}
	.FAQquestions .accordion {
		font-size: 16px;
		padding: 24px 20px;
		height: auto;
	}
	.FAQquestions .accordion-panel p {
		font-size: 13px;
	}
	.gamfisubscriveSect input {
		max-width: 300px;
		height: 52px;
	}
	.ProjectV2_TabBtns ul li button {
		width: 100px;
		padding: 0px !important;
	}
	.RoadmapTabBtns ul li button {
		width: 90px;
	}

	.Tier_System_Headings ul {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0px 26px;
		flex-direction: column;
		width: auto;
		align-items: flex-start;
	}
	.Tier_System_Headings ul li {
		font-size: 16px;
	}
	.Tier_System_Items ul {
		flex-direction: column;
		align-items: flex-start;
	}
	.Tier_System_Items ul li strong {
		font-size: 16px;
	}
	.Tier_System_Items ul li {
		font-size: 16px;
		margin-bottom: 16px;
		width: auto !important;
	}
	.Tier_System_Items ul li span {
		min-width: 40px;
		max-width: 40px;
	}

	.Tier_System_Items ul li.WeightItem {
		margin-bottom: 0px;
	}

	.Tier_System_Items .bgShape {
		left: 17%;
	}

	.slick-prev {
		left: 0%;
		top: 0%;
	}

	.slick-next {
		left: 0%;
		top: 0%;
	}
	.sc_banner_V3_right ul {
		margin-bottom: 50px;
	}
	.GamfiV3_ProjectPoolsBtn li button {
		width: 80px;
		height: 40px;
		font-size: 10px;
	}
	.How_to_Participate_right_Contnet {
		margin-top: 50px;
	}
	.RoadMapShape {
		display: none;
	}

	.TokenomicsContentsLeftCustom {
		margin-left: 0px;
	}

	.TvlRightCard {
		margin: auto;
		margin-top: 30px;
	}
	.TvlHeadings {
		width: 170px;
		margin: auto;
	}
	.TvlSect {
		text-align: center;
		margin-bottom: 40px;
	}
	.TvlHeadings span {
		position: absolute;
		top: -45px;
		left: 0px;
	}

	.TiedV2CollBody {
		padding: 0px 19px;
	}
	.TierV2_Col {
		margin-bottom: 100px;
	}

	.tierV2ColHeadings,
	.TiedV2CollBody {
		padding: 0px;
	}
	.TiedV2CollBody1:after {
		content: "";
		position: absolute;
		display: block;
		top: 320px;
		left: 0px;
		height: 4px;
		width: 100%;
		transform: rotate(0deg);
	}
	.TiedV2CollBody2:after {
		content: "";
		position: absolute;
		display: block;
		top: 395px;
		left: 0px;
		height: 4px;
		width: 100%;
		transform: rotate(0deg);
	}

	.TiedV2CollBody3:after {
		content: "";
		position: absolute;
		display: block;
		top: 455px;
		left: 0px;
		height: 4px;
		width: 100%;
		transform: rotate(0deg);
	}

	.TiedV2CollBody4:after {
		content: "";
		position: absolute;
		display: block;
		top: 515px;
		left: 0px;
		height: 4px;
		width: 100%;
		transform: rotate(0deg);
	}
	.ConnectWallet_Btn span {
		min-width: 24px;
		max-width: 24px;
		margin-right: 10px;
	}
	.ConnectWallet_Btn {
		font-size: 13px;
		padding: 10px 15px;
	}
	.ConnectWallet_Btn:before {
		top: 20px;
		right: 16px;
	}

	.ChooseNetwork_Sect ul {
		flex-wrap: wrap;
	}
	.ChooseNetwork_Sect ul li {
		width: 47%;
		margin-bottom: 16px;
	}

	.Latest_Articles_Card {
		min-height: auto;
	}

	.FAQ_Screen_Tab_Sect {
		padding-right: 0px;
	}
	.FAQ_Screen_Tab_Content {
		padding-left: 0px;
	}
	.FAQContnetSect .accordion {
		font-size: 18px;
		padding-right: 25px;
	}
	.FAQContnetSect .accordion-panel p {
		font-size: 14px;
	}

	.ProjectClasicSliderContent {
		flex-direction: column;
	}

	.ProjectClasicSlider_RightSect {
		max-width: 100%;
		margin-top: 30px;
	}
	.ProjectClasicSliderLeftSect {
		max-width: 100%;
		padding-right: 0px;
		margin-bottom: 30px;
	}
	.Access_AllocationText {
		align-items: flex-start;
	}
	.ProjectClasicSlider_RightTimer {
		margin-bottom: 20px;
	}
	.ProjectClasicSlider_progressSect {
		margin-top: 40px;
	}
	.Project_clasicSliderSect .ProjectClasicPrev {
		left: -10%;
	}
	.Project_clasicSliderSect .ProjectClasicNxt {
		right: -10%;
	}

	.Project_clasicSliderSect .ProjectClasicPrev img,
	.Project_clasicSliderSect .ProjectClasicNxt img {
		height: 30px;
	}
	.liveProoject_Headings {
		max-width: 220px;
	}
	.liveProoject_Headings .video__icon {
		top: 0px;
	}
	.liveProoject_Headings h2 {
		font-size: 22px;
	}

	.Project_clasic_2Container_RightSect {
		margin-top: 30px;
	}
	.Project_clasic_2Container_RightHeadings h2 {
		margin-bottom: 10px;
	}
	.Project_clasic_2Container_RightHeadings h2 a {
		font-size: 27px;
	}

	.PoolAndTokenInfo_Sect {
		margin-top: 40px;
	}
	.First_Survivor_CityRightSection {
		padding: 0px;
		margin-bottom: 40px;
	}
	.PoolAndTokenInfo_card {
		margin-top: 30px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 619px) {
	.kycForm .UploadDocSect ul li h4 {
		font-size: 12px;
	}
}

@media only screen and (max-width: 575px) {
	.sc-banner .banner-content .banner-title {
		font-size: 28px;
		margin-bottom: 10px;
	}
	.sc-banner .banner-content .description {
		font-size: 16px;
		margin: 0 0 30px;
	}
	.view-more {
		padding: 10px 20px 6px;
		display: inline-block;
	}
	.sec-inner.d-flex,
	.heading-area.d-flex {
		display: block !important;
		margin-bottom: 40px;
	}
	.gamfi-tokenomics-section .pr-63,
	.gamfi-tokenomics-section h5 {
		margin-bottom: 0;
	}
	.gamfi-footer-section .footer-listing .footer-icon-list i {
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin: 0 4px;
		font-size: 15px;
	}
	.gamfi-footer-section .footer-cta-area .footer-link input[type="email"] {
		padding: 12px 15px;
	}
	.project-summary-menu {
		margin-bottom: 55px;
	}
	.gamfi-calendar-list .calender-date {
		width: 48%;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form {
		display: block;
	}
	.breadcrumbs-form .gamfi-navigation {
		margin: 30px 0 0;
	}
	.breadcrumbs-form .gamfi-navigation ul li {
		margin-right: 15px;
	}
	.breadcrumbs-form .gamfi-navigation ul {
		justify-content: flex-start;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form input[type="text"] {
		width: 100%;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-form form {
		margin: 0;
	}

	.kycQrcodeImg {
		height: 280px;
		width: 280px;
	}
	.kycForm h3 {
		font-size: 19px;
	}
	.kycForm p {
		font-size: 14px;
		line-height: 24px;
	}
	.kycCountryDropbox h4 {
		font-size: 14px;
	}
	.kycForm {
		padding: 0px;
	}

	.kycForm .UploadDocSect ul li h4 {
		width: 60%;
		margin: auto;
		line-height: 158%;
		margin-top: 16px;
	}

	.Market_CapSect .redBordergradient {
		top: -148px;
		left: 148px;
		width: 5px;
		height: 300px;
	}

	.Market_CapSect .greenBordergradient {
		bottom: -148px;
		right: 148px;
		width: 5px;
		height: 300px;
	}
	.ProjectV2_TabBtns ul {
		justify-content: flex-start;
	}
	.RoadmapTabBtns ul {
		justify-content: flex-start;
	}
	.Tier_System_Items .bgShape {
		left: 14%;
	}

	.GamfiV3_ProjectPoolsBtn {
		justify-content: flex-start;
	}

	/*New CSS*/
	.blog-detail-conent .blog-area .blog-description .blockquote .des-active {
		font-size: 18px;
	}
	.blog-detail-conent .previous-item .previous-text h5 {
		font-size: 15px;
	}
	.blog-detail-conent .blog-area .blog-description .des {
		font-size: 17px;
	}
	.kycQrcodeImg {
		height: 280px;
		width: 280px;
	}
	.kycForm h3 {
		font-size: 19px;
	}
	.kycForm p {
		font-size: 14px;
		line-height: 24px;
	}
	.kycCountryDropbox h4 {
		font-size: 14px;
	}
	.kycForm {
		padding: 0px;
	}

	.kycForm .UploadDocSect ul li h4 {
		width: 60%;
		margin: auto;
		line-height: 158%;
		margin-top: 16px;
	}

	.tokenomics-counter {
		text-align: center;
	}

	.Project_clasicSliderSect .ProjectClasicPrev {
		left: 0%;
	}
	.Project_clasicSliderSect .ProjectClasicNxt {
		right: 0%;
	}

	.ProjectClasicSlider_TextSect h3 a {
		font-size: 25px;
	}
	.liveProoject_Headings {
		max-width: 220px;
	}
	.liveProoject_Headings h2 {
		font-size: 22px;
	}
	.liveProoject_Headings .video__icon {
		top: 0px;
	}
}

@media (max-width: 540px) {
	.gamfisubscriveSect input {
		margin-bottom: 20px;
	}
	.gamfisubscriveSect {
		flex-direction: column;
	}

	.First_Survivor_CityLeft_Timer {
		width: 245px;
		height: 40px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul {
		width: 165px;
		padding-top: 8px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul li {
		font-size: 17px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul li span {
		font-size: 10px;
	}
	.First_Survivor_CityLeftSection .ChainImg {
		width: 40px;
	}
	.First_Survivor_CityRight_ProjectName {
		margin-left: 20px;
	}
	.First_Survivor_CityRight_ProjectName h2 {
		margin-bottom: 0px;
	}
	.First_Survivor_CityRight_ProjectName h2 a {
		font-size: 23px;
	}
	.First_Survivor_CityRight_ProjectName span {
		font-size: 13px;
	}
	.First_Survivor_CityRight_ProjectImg {
		height: 70px;
		width: 70px;
	}

	.First_Survivor_CityRightSection p {
		font-size: 13px;
		line-height: 26px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.First_Survivor_CityRight_Button button {
		font-size: 13px;
		height: 40px;
	}
	.First_Survivor_CityLeftSection .TotalRisedProgressSect {
		margin-top: 100px;
	}

	.PoolAndTokenInfo_List ul li p.PoolAndTokenInfo_List_left,
	.PoolAndTokenInfo_List ul li p.PoolAndTokenInfo_ListRight {
		font-size: 13px;
	}
	.PoolAndTokenInfo_List ul li p.PoolAndTokenInfo_ListRight span {
		margin-right: 15px;
	}

	.ProjectDetails2_ScheduleTableHeadings ul li,
	.ProjectDetails2_ScheduleTablebody ul li,
	.ProjectDetails2_AllocationsTableHeadings ul li,
	.ProjectDetails2_AllocationsTableBody ul li {
		font-size: 12px;
	}

	.ProjectDetails2_ScheduleTableHeadings,
	.ProjectDetails2_ScheduleTablebody ul,
	.ProjectDetails2_AllocationsTableHeadings,
	.ProjectDetails2_AllocationsTableBody ul {
		height: 40px;
	}
	.ProjectDetails2_ScheduleTableHeadings,
	.ProjectDetails2_ScheduleTablebody,
	.ProjectDetails2_AllocationsTableHeadings,
	.ProjectDetails2_AllocationsTableBody {
		min-width: 800px;
	}
	.ProjectDetails2_AllocationsTableBody ul li.column_5 button {
		height: 33;
		width: 85;
		font-size: 12px;
	}
	.ProjectDetails2_AllocationsTableBody ul li.column_5 button {
		height: 32px;
	}
}

@media only screen and (max-width: 500px) {
	.kycForm .UploadDocSect ul li h4 {
		width: 100px;
		margin: auto;
		line-height: 158%;
		margin-top: 16px;
	}
	.gamfi_V2_hero_Left h2 {
		font-size: 33px;
	}
	.gamfi_V2_hero_Left p {
		font-size: 15px;
	}

	.buyTokenlogos ul {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.buyTokenlogos ul li {
		width: 47%;
		margin-right: 0px;
		margin-bottom: 26px;
	}
	.Market_CapAllContent {
		justify-content: space-around;
	}
	.Market_CapContent {
		width: 50%;
		margin: 15px 0px;
	}
	.Tier_System_Items .bgShape {
		left: 12%;
	}

	.gamfi_FAQ_Sect .sec-heading .sub-title {
		font-size: 12px;
	}
}

@media only screen and (max-width: 480px) {
	body {
		font-size: 15px;
	}
	.gamfi-header-section .logo {
		margin-right: 30px;
	}
	.gamfi-header-section .white-btn {
		display: none;
	}
	.footer-logo img,
	.sidebar-logo img,
	.gamfi-header-section .logo img {
		max-width: 120px;
	}
	.gamfi-header-section .gamfi-btn-area ul .nav-expander {
		margin-top: 0;
	}
	.sc-banner {
		padding-top: 120px;
	}
	.gamfi-header-section .right_menu_togle {
		padding: 66px 30px 50px 30px;
	}
	.gamfi-header-section .right_menu_togle .close-btn {
		right: 0;
	}
	.gamfi-header-section .right_menu_togle {
		max-width: 300px;
	}
	.sc-banner .banner-content .banner-title {
		font-size: 24px;
	}
	.gamfi-project-section .gamfi-btn-area .readon,
	.banner-btn {
		padding: 9px 24px 8px;
	}
	.gamfi-about-secion .game-price-item {
		padding-left: 15px;
		padding-right: 15px;
	}
	.banner-slider-inner .timer ul li {
		font-size: 26px;
	}
	.apply-heading h2,
	.gamfi-footer-section .footer-cta-area .title,
	.sec-heading .title {
		font-size: 22px;
	}
	.participat-information .project-value-inner {
		display: block !important;
	}
	.gamfi-footer-section .footer-cta-form form,
	.project-details-conent .banner-bottom-content,
	.gamfi-previous-section .previous-item .previous-raise,
	.gamfi-previous-section .previous-item .previous-gaming {
		display: block;
	}
	.gamfi-previous-section .previous-item .previous-gaming .previous-image {
		margin-bottom: 15px;
	}
	.gamfi-previous-section .previous-item .previous-raise .progress-inner {
		margin-top: 10px;
	}
	.gamfi-explore-content .project-item h4 {
		font-size: 18px;
	}
	.gamfi-about-secion .game-price-item .price-details h3 {
		font-size: 20px;
	}
	.gamfi-footer-section .footer-mainmenu ul li {
		padding: 0 0 10px;
	}
	.gamfi-footer-section .footer-mainmenu ul li,
	.gamfi-tokenomics-section .tokenomics-item,
	.gamfi-project-section .project-item .project-info {
		display: block !important;
	}
	.gamfi-project-section .project-item .project-info img {
		margin: 0 0 10px;
	}
	.gamfi-tokenomics-section .tokenomics-menu {
		padding: 0 0 20px;
	}
	.tokenomics-counter .counter-number {
		font-size: 28px;
	}
	.sec-heading .heading-right-image,
	.sec-heading .heading-left-image {
		max-width: 60px;
	}
	.sec-heading .heading-right-image,
	.sec-heading .heading-left-image,
	.sec-heading .sub-title {
		margin: 0;
	}
	.gamfi-footer-section .footer-listing .footer-icon-list i {
		width: 32px;
		height: 32px;
		line-height: 32px;
		margin: 0 2px;
		font-size: 13px;
	}
	.gamfi-team-section .team-item {
		margin-bottom: 20px;
	}
	.gamfi-form-content .address-form h5,
	.gamfi-form-content .address-form .input-button label {
		font-size: 13px;
	}
	.gamfi-explore-content .project-menu-area ul li,
	.gamfi-calendar-list .calender-date {
		width: 100%;
	}
	.gamfi-explore-content .project-menu-area ul {
		display: block;
	}
	.gamfi-explore-content .project-item {
		padding-left: 15px;
		padding-right: 15px;
	}
	.gamfi-explore-content .project-item .heading-title h4:before {
		left: -15px;
	}
	.gamfi-farming-content .expeand-centent {
		padding: 20px 15px;
	}
	.gamfi-navigation ul li a:not(.black-shape) {
		padding: 3px 12px;
	}
	.gamfi-navigation ul li:first-child a {
		padding: 3px 12px 3px;
	}
	.gamfi-breadcrumbs-section .breadcrumbs-link,
	.sec-heading .sub-title {
		font-size: 15px;
	}
	.breadcrumbs-form .gamfi-navigation ul li:first-child a,
	.gamfi-breadcrumbs-section .gamfi-navigation ul li a {
		padding: 6px 15px;
	}
	.project-details-conent table {
		display: block;
		overflow-x: auto;
	}
	.project-details-conent table td {
		font-size: 14px;
	}
	.participat-information .project-item h3 {
		font-size: 20px;
		margin-bottom: 5px;
	}
	.participat-information .project-item h4 {
		font-size: 18px;
	}
	.project-details-conent .project-item .date-listing li label {
		width: 100%;
		margin-bottom: 10px;
	}
	.project-details-conent .project-item .date-listing li {
		display: block;
	}
	.project-details-conent .project-item .date-listing li + li {
		margin-left: 0;
	}
	.project-details-conent .project-media li span {
		float: none;
		display: block;
	}
	.participat-information .project-value-inner .project-value {
		margin-bottom: 10px;
	}
	.project-details-conent
		.project-item
		.project-form-list
		.balance-form-area
		.white-shape-small,
	.project-details-conent .project-item .big-text {
		position: static;
	}
	.gamfi-about-secion .game-price-item .text-content {
		margin: 10px 0;
	}
	.gamfi-about-secion .game-price-item .social-icon-list,
	.gamfi-about-secion .game-price-item .text-content {
		text-align: left;
	}
	.gamfi-footer-section .footer-cta-form .footer-link input[type="email"] {
		margin-bottom: 20px;
	}
	.gamfi-footer-section .footer-cta-form .footer-link input[type="email"],
	.gamfi-about-secion .game-price-item .btn-area,
	.gamfi-about-secion .game-price-item .text-content,
	.gamfi-about-secion .game-price-item .social-area,
	.project-details-conent
		.project-item
		.project-form-list
		.balance-form-area
		input,
	.project-details-conent
		.project-item
		.project-form-list
		.balance-form-area
		input[type="submit"],
	.project-details-conent
		.project-item
		.project-form-list
		.balance-form-area
		.approve
		input[type="submit"] {
		width: 100%;
	}

	.project-details-conent .project-item ul.date-listing {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.project-details-conent .project-item ul.date-listing li {
		width: 24%;
	}
	.project-details-conent .project-item ul.date-listing li button {
		font-size: 10px;
		width: 100%;
		height: 100%;
		padding: 12px 0px;
	}
	.Gamfi_Header_ProgressBar .progress {
		width: 290px;
	}
	.TeamMembersInfo h2 {
		font-size: 30px;
	}
	.TeamMembersInfo h3 {
		font-size: 20px;
	}
	.TeamMembersInfo p {
		font-size: 14px;
		line-height: 25px;
	}
	.TeamMembersContact ul li strong {
		font-size: 14px;
		width: 120px;
	}
	.TeamMembersContact ul li span {
		font-size: 14px;
	}
	.kycForm .UploadBtnBg {
		height: 130px;
	}
	.kycForm .UploadDocSect .upload-btn-wrapper span {
		height: 50px;
		width: 50px;
	}

	.kycPersonalinfoForm form .InputNameSect,
	.kycPersonalinfoForm form .InputAddressSect,
	.kycPersonalinfoForm form .InputCitySect {
		flex-direction: column;
		align-items: flex-start;
	}
	.kycPersonalinfoForm form .InputNameSect .FirstNameFild,
	.kycPersonalinfoForm form .InputNameSect .LastNameFild,
	.kycPersonalinfoForm form .InputAddressSect .Address1Fild,
	.kycPersonalinfoForm form .InputAddressSect .Address2Fild,
	.kycPersonalinfoForm form .InputCitySect .CityFild,
	.kycPersonalinfoForm form .InputCitySect .PostCodeFild {
		width: 100%;
	}
	.SignUp_SignIn_Form {
		padding: 63px 20px 56px 20px;
	}
	.SignUp_SignIn_Form h4,
	.SignUp_SignIn_Form h4 a {
		font-size: 14px;
	}
	.RodmapConetentbox1 {
		min-height: 158px;
		background: url(../images/kycimg/roadMapcpntentBg1.svg);
		background-position: right;
		background-size: cover;
	}
	.RodmapConetentbox2 {
		min-height: 185px;
		background: url(../images/kycimg/roadMapcpntentBg5.svg);
		background-position: left;
		background-size: cover;
	}
	.RodmapConetentbox3 {
		min-height: 158px;
		background: url(../images/kycimg/roadMapcpntentBg2.svg);
		background-position: right;
		background-size: cover;
	}
	.RodmapConetentbox4 {
		min-height: 216px;
		background: url(../images/kycimg/roadMapcpntentBg6.svg);
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox5 {
		min-height: 260px;
		background: url(../images/kycimg/roadMapcpntentBg3.svg);
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox6 {
		background: url(../images/kycimg/roadMapcpntentBg7.svg);
		min-height: 230px;
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox7 {
		min-height: 185px;
		background: url(../images/kycimg/roadMapcpntentBg4.svg);
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.connectWalletBtnforMobile {
		display: block;
	}
	.Multi_Chain_Support_right_list ul {
		margin-right: 40px;
	}

	.ProjectV2_TabBtns ul li {
		margin-left: 10px;
	}
	.ProjectV2_TabBtns ul li button {
		width: 92px;
	}
	.ProjectV2_TabBtns ul li button .btn-text {
		font-size: 12px;
	}

	.RoadmapTabBtns ul li {
		margin-left: 10px;
	}
	.Tier_System_Items .bgShape {
		left: 10%;
	}

	.sc_banner_V3_left h2 {
		font-size: 35px;
	}

	.ConnectWallet_Card_Headings h2 {
		font-size: 31px;
		margin-bottom: 16px;
	}
	.ConnectWallet_Card_Headings p,
	.ConnectWallet_Content p {
		font-size: 14px;
	}

	/*New CSS*/
	.latest-details-content .latest-item p {
		font-size: 13px;
	}
	.post-item .recent-content,
	.blog-detail-conent .tag-menu ul li {
		display: block;
	}
	.post-item .post-shade h4 {
		font-size: 20px;
	}
	.latest-details-content .tag-menu ul li {
		display: block;
	}
	.newsletter-item.hover-shape-inner .hover_shape1 {
		left: 10%;
	}
	.newsletter-item.hover-shape-inner .hover_shape2 {
		left: 56.5%;
	}
	.newsletter-item.hover-shape-inner .hover_shape3 {
		left: 32%;
	}
	.blog-detail-conent .blog-area .Tokenomics-item .project-service {
		padding-left: 40px;
	}
	.gamfi-breadcrumbs-section.blog-breadcrumbs .sec-heading h1 {
		font-size: 25px;
	}
	.blog-detail-conent
		.blog-area
		.about-blog
		.about-menu
		.about-list
		.about-listing
		li,
	.blog-detail-conent .blog-area .about-blog .about-menu {
		display: block;
	}
	.blog-detail-conent
		.blog-area
		.about-blog
		.about-menu
		.about-list
		.about-listing
		li {
		display: block;
		margin-bottom: 15px;
	}
	.blog-detail-conent .blog-area .about-blog .blog-contact {
		display: block;
	}
	.blog-detail-conent .blog-area .about-blog .blog-contact .previous-item {
		display: block;
		margin-bottom: 30px;
	}
	.latest-details-content .latest-item p {
		font-size: 14px;
	}
	.Gamfi_Header_ProgressBar .progress {
		width: 290px;
	}
	.TeamMembersInfo h2 {
		font-size: 30px;
	}
	.TeamMembersInfo h3 {
		font-size: 20px;
	}
	.TeamMembersInfo p {
		font-size: 14px;
		line-height: 25px;
	}
	.TeamMembersContact ul li strong {
		font-size: 14px;
		width: 120px;
	}
	.TeamMembersContact ul li span {
		font-size: 14px;
	}
	.kycForm .UploadBtnBg {
		height: 130px;
	}
	.kycForm .UploadDocSect .upload-btn-wrapper span {
		height: 50px;
		width: 50px;
	}

	.kycPersonalinfoForm form .InputNameSect,
	.kycPersonalinfoForm form .InputAddressSect,
	.kycPersonalinfoForm form .InputCitySect {
		flex-direction: column;
		align-items: flex-start;
	}
	.kycPersonalinfoForm form .InputNameSect .FirstNameFild,
	.kycPersonalinfoForm form .InputNameSect .LastNameFild,
	.kycPersonalinfoForm form .InputAddressSect .Address1Fild,
	.kycPersonalinfoForm form .InputAddressSect .Address2Fild,
	.kycPersonalinfoForm form .InputCitySect .CityFild,
	.kycPersonalinfoForm form .InputCitySect .PostCodeFild {
		width: 100%;
	}
	.SignUp_SignIn_Form {
		padding: 63px 20px 56px 20px;
	}
	.SignUp_SignIn_Form h4,
	.SignUp_SignIn_Form h4 a {
		font-size: 14px;
	}
	.RodmapConetentbox1 {
		min-height: 158px;
		background: url(../images/kycimg/roadMapcpntentBg1.svg);
		background-position: right;
		background-size: cover;
	}
	.RodmapConetentbox2 {
		min-height: 185px;
		background: url(../images/kycimg/roadMapcpntentBg5.svg);
		background-position: left;
		background-size: cover;
	}
	.RodmapConetentbox3 {
		min-height: 158px;
		background: url(../images/kycimg/roadMapcpntentBg2.svg);
		background-position: right;
		background-size: cover;
	}
	.RodmapConetentbox4 {
		min-height: 216px;
		background: url(../images/kycimg/roadMapcpntentBg6.svg);
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox5 {
		min-height: 260px;
		background: url(../images/kycimg/roadMapcpntentBg3.svg);
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox6 {
		background: url(../images/kycimg/roadMapcpntentBg7.svg);
		min-height: 230px;
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.RodmapConetentbox7 {
		min-height: 185px;
		background: url(../images/kycimg/roadMapcpntentBg4.svg);
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.ProjectClasicSliderBG {
		padding: 30px;
	}

	.Project_clasic_2Container_RightHeadings h2 {
		margin-bottom: 5px;
	}
	.Project_clasic_2Container_RightHeadings h2 a {
		font-size: 22px;
	}
	.Project_clasic_2Container_RightHeadings p {
		font-size: 14px;
		margin-bottom: 20px;
	}
	.Project_clasic_2Container_RightHeadings span {
		height: 30px;
		width: 30px;
	}
	.TotalRisedProgressHeadings h3,
	.TotalRisedProgressHeadings p {
		font-size: 12px;
	}
	.TotalRisedProgresSaleAirdropSect p {
		font-size: 12px;
	}
	.Access_Allocation_ParticipantsSect ul li span,
	.Access_Allocation_ParticipantsSect ul li p {
		font-size: 12px;
	}
	.Project_clasic_2ContainerSocialSect ul {
		max-width: 200px;
	}
	.Project_clasic_2ContainerSocialSect ul li a i {
		font-size: 16px;
	}
	.Project_clasic_2_ContainerLeft {
		min-height: 335px;
	}
	.Access_Allocation_ParticipantsSect {
		margin-top: 10px;
	}
	.Project_clasic_2ContainerSocialSect {
		margin-top: 35px;
	}
	.ProjectClasic2_Timer {
		width: 210px;
	}
	.Project_clasic_2_LeftContentImg {
		height: 50px;
		width: 50px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul {
		max-width: 140px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul li {
		font-size: 13px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul li span {
		font-size: 10px;
	}
}

@media (max-width: 425px) {
	.Tier_System_Items .bgShape {
		left: 15px;
	}
	.ConnectWallet_Card {
		padding: 50px 20px;
	}

	.ListInvestorsList ul li {
		width: 50%;
	}
	.ListInvestorsList ul li img {
		width: 70%;
	}

	.ListInvestorsList ul li.first {
		text-align: left;
	}

	.ListInvestorsList ul li.second {
		text-align: right;
	}

	.ListInvestorsList ul li.third {
		text-align: left;
	}

	.ListInvestorsList ul li.last {
		text-align: right;
	}
}

@media (max-width: 375px) {
	.modal-content {
		background: #1e1f35;
		padding: 30px 10px 5px;
	}

	.gamfi_V2_hero_Left h2 {
		font-size: 30px;
	}
	.gamfi_V2_hero_Left p {
		font-size: 14px;
	}
	.TokenomicsListSect ul {
		margin-right: 40px;
	}
	.Multi_Chain_Support_right_list ul {
		margin-right: 25px;
	}
	.Multi_Chain_Support_right_list ul li {
		font-size: 12px;
	}

	.ChooseNetwork_Sect ul li button span {
		margin-right: 11px;
		min-width: 20px;
		max-width: 20px;
	}

	.V1_tokenomics_content_ListProgress1 {
		width: 80px;
	}
	.V1_tokenomics_content_ListProgress2 {
		width: 100px;
	}
	.V1_tokenomics_content_ListProgress3 {
		width: 115px;
	}
	.V1_tokenomics_content_ListProgress4 {
		width: 130px;
	}
	.V1_tokenomics_content_ListProgress5 {
		width: 150px;
	}
	.V1_tokenomics_content_ListProgress6 {
		width: 170px;
	}
	.V1_tokenomics_content_ListProgress7 {
		width: 220px;
	}

	.ProjectClasicSlider_ImgAndTitleSect img {
		max-width: 60px;
	}
	.ProjectClasicSlider_TextSect h3 {
		margin-bottom: 0px;
	}
	.ProjectClasicSlider_TextSect h3 a {
		font-size: 19px;
	}
	.ProjectClasicSlider_TextSect p {
		font-size: 12px;
	}
	.ProjectClasicSlider_RiseCeontent h2,
	.Access_AllocationText h2 {
		font-size: 13px;
	}
	.ProjectClasicSlider_RiseCeontent span,
	.Access_AllocationText span {
		font-size: 11px;
	}
	.ProjectClasicSlider_RightTimer .price-counter .timer ul li {
		font-size: 25px;
	}

	.Project_clasic_2_ContainerLeft {
		min-height: 250px;
	}
	.ProjectClasic2_Timer {
		width: 160px;
		background-position: center right;
		top: 20px;
	}
	.ProjectClasic2_Timer .price-counter .timer ul {
		margin-left: 10px;
		margin-top: 10px;
		max-width: 120px;
	}

	.Project_clasic_2_LeftContentImg {
		height: 40px;
		width: 40px;
	}
	.Access_Allocation_ParticipantsSect ul li span,
	.Access_Allocation_ParticipantsSect ul li p {
		font-size: 10px;
		line-height: 158%;
	}
	.TotalRisedProgressHeadings h3,
	.TotalRisedProgressHeadings p {
		font-size: 10px;
		line-height: 158%;
	}

	.TotalRisedProgressBg {
		height: 10px;
	}

	.Project_clasic_2Container_RightHeadings h2 a {
		font-size: 18px;
	}

	.Project_clasic_2Container_RightHeadings p {
		font-size: 12px;
	}

	.TotalRisedProgresSaleAirdropSect p {
		font-size: 10px;
	}
	.Project_clasic_2Container_RightSect {
		margin-top: 15px;
	}

	.First_Survivor_CityRight_ProjectName h2 a {
		font-size: 22px;
	}

	.First_Survivor_CityLeft_Timer {
		width: 220px;
		height: 40px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul {
		width: 140px;
		padding-top: 8px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul li {
		font-size: 16px;
	}
	.First_Survivor_CityLeft_Timer .price-counter ul li span {
		font-size: 9px;
	}
	.First_Survivor_CityLeftSection .ChainImg {
		width: 30px;
	}
}

@media (max-width: 360px) {
	.forgetPassBtnSect {
		flex-direction: column;
		align-items: flex-start;
	}
	.forgetPassBtnSect .ForgetPass a {
		margin-left: 25px;
		margin-top: 16px;
	}
	.SignUp_SignIn_Form h4 {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.gamfi_V2_hero_Left h2 {
		font-size: 27px;
	}
	.gamfi_V2_hero_Btns .VIEW_Projects_Btn,
	.gamfi_V2_hero_Btns .Apply_Project_Btn {
		width: 130px;
	}
	.gamfi_V2_hero_Btns .VIEW_Projects_Btn .btn-text,
	.gamfi_V2_hero_Btns .Apply_Project_Btn .btn-text {
		font-size: 12px;
	}
	.TokenomicsContentsLeft h3 {
		font-size: 20px;
	}
	.ConnectWallet_Card_Headings h2 {
		font-size: 28px;
	}
	.Article_Details2 h4 {
		font-size: 26px;
		line-height: 45px;
	}
	.FAQ_Screen_Tab {
		padding: 34px 30px;
	}

	.ProjectSammeryTabsSect button a {
		font-size: 14px;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(
		115deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-size: 30px;
}
